<template>
  <v-container id="calendar" fluid tag="section">
    <base-v-component heading="Appointment" link="components/calendars" />
    <v-row>
      <v-col cols="12" md="10" class="mx-auto">
        <v-card>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="d-md-flex d-none">{{ branchName }}</span>
                    <span
                      class="d-sm-flex d-md-none text-truncate"
                      style="max-width: 30px"
                      >{{ branchName }}</span
                    >
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list shaped>
                  <v-list-item-group
                    v-model="selectedBranch"
                    color="primary"
                    mandatory
                  >
                    <v-list-item value="0">All</v-list-item>
                    <v-list-item
                      v-for="(item, index) in branchItem"
                      :key="index"
                      :value="item.branch_id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-spacer> </v-spacer>
              <v-btn
                class="mr-4 d-none d-sm-block white--text"
                color="secondary"
                @click="setToday"
              >
                Today
              </v-btn>

              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>

              <h4 v-if="$refs.calendar" class="text-center">
                {{ $refs.calendar.title }}
              </h4>

              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>

              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="(type = 'day'), getThisWeekBooking()">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="(type = 'week'), getThisWeekBooking()">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="(type = 'month'), getThisWeekBooking()">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="(type = '4day'), getThisWeekBooking()">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600" flat class="mt-5">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="secondary"
              :events="getSpecificBranchBooking"
              :event-color="selectedEvent.color"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
              color="white"
            >
              <!-- <v-card color="grey lighten-4" min-width="350px" flat> -->
              <v-toolbar :color="selectedEvent.color" dark>
                <v-dialog persistent :retain-focus="false" max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        (selectedPerson = selectedEvent.person),
                          (selectedDate = selectedEvent.dateFormat),
                          (selectedTime = selectedEvent.time),
                          (selectedCustomerName = selectedEvent.customerName),
                          (selectedCustomerEmail = selectedEvent.email),
                          (selectedCustomerContact = selectedEvent.contact),
                          (selectedCustomerRemark = selectedEvent.remark),
                          (selectedBookingID = selectedEvent.bookingID),
                          (selectedBranchID = selectedEvent.branchID),
                          (selectedCustomerID = selectedEvent.customerID),
                          (selectedService = selectedEvent.serviceID),
                          (selectedProvider = selectedEvent.providerID),
                          (birthdayEdit = selectedEvent.birthday),
                          (addressEdit = selectedEvent.address)
                      "
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar :color="selectedEvent.color" dark
                        >Edit appointment</v-toolbar
                      >
                      <v-card-text>
                        <v-alert
                          class="mt-3"
                          dense
                          outlined
                          type="error"
                          v-if="incompleteInfo"
                        >
                          Please <strong>complete</strong> all the information
                          needed
                        </v-alert>
                        <v-row class="mt-2">
                          <v-col cols="12">
                            <v-select
                              :items="branchItem"
                              v-model="selectedBranchID"
                              item-text="name"
                              item-value="branch_id"
                              label="Branch"
                              prepend-icon="mdi-store"
                              @change="
                                getBranchWorkDayAndTimeForUpdate(),
                                  (selectedPerson = ''),
                                  (selectedDate = ''),
                                  (selectedService = ''),
                                  (selectedProvider = ''),
                                  (serviceDuration = ''),
                                  (providerBreakTime = []),
                                  (providerWorkDay = []),
                                  (providerWorkTime = []),
                                  (slot = '')
                              "
                            ></v-select>
                          </v-col>

                          <v-col cols="12" v-if="industry != 'F&B'">
                            <v-select
                              :items="serviceItem"
                              v-model="selectedService"
                              item-text="title"
                              item-value="service_id"
                              label="Service"
                              prepend-icon="mdi-toolbox"
                              @change="
                                getServiceInfo(),
                                  (selectedProvider = ''),
                                  (selectedDate = ''),
                                  (providerBreakTime = []),
                                  (providerWorkDay = []),
                                  (providerWorkTime = []),
                                  (slot = '')
                              "
                              v-if="selectedBranchID"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" v-if="industry != 'F&B'">
                            <v-select
                              :items="providerItem"
                              v-model="selectedProvider"
                              item-text="name"
                              item-value="provider_id"
                              label="Provider"
                              prepend-icon="mdi-account-multiple"
                              @click="calendarForEdit = false"
                              @change="
                                getSelectedProviderForUpdate(),
                                  (calendarForEdit = true),
                                  (selectedDate = '')
                              "
                              v-if="selectedBranchID && selectedService"
                            ></v-select>
                          </v-col>

                          <v-col cols="12" v-if="industry == 'F&B'">
                            <v-text-field
                              v-model="selectedPerson"
                              label="Total Person"
                              prepend-icon="mdi-account"
                              @click="
                                (calendarForEdit = false), (selectedPerson = '')
                              "
                              @input="
                                (calendarForEdit = true),
                                  (selectedDate = ''),
                                  $v.selectedPerson.$touch()
                              "
                              :error-messages="selectedPersonErrors"
                              required
                              @blur="$v.selectedPerson.$touch()"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                              v-if="
                                (selectedBranchID &&
                                  selectedPerson &&
                                  calendarForEdit &&
                                  !$v.selectedPerson.$invalid) ||
                                (selectedService && selectedProvider)
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="selectedDate"
                                  label="Choose your date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <FunctionalCalendar
                                v-model="selectedCalendarDate"
                                :limits="{
                                  min: today,
                                  max: '01/01/3000',
                                }"
                                :disabledDates="branchHoliday"
                                :disabled-day-names="weekdays"
                                :hidden-elements="['leftAndRightDays']"
                                :is-date-picker="true"
                                v-on:choseDay="
                                  selectedDate =
                                    selectedCalendarDate.selectedDate;
                                  getBookingToUpdate();
                                  selectedTime = '';
                                "
                              >
                              </FunctionalCalendar>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              :items="timesPlusDuration"
                              v-model="selectedTime"
                              label="Pick your time"
                              prepend-icon="mdi-clock"
                              single-line
                              v-if="
                                (selectedDate &&
                                  selectedPerson &&
                                  calendarForEdit) ||
                                (selectedProvider &&
                                  selectedService &&
                                  selectedDate)
                              "
                            ></v-select>
                          </v-col>

                          <v-col cols="12">
                            <v-textarea
                              v-model="selectedCustomerRemark"
                              label="Customer Remark"
                              rows="5"
                              prepend-icon="mdi-comment"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="selectedCustomerName"
                              label="Customer name "
                              prepend-icon="mdi-account"
                              :error-messages="selectedCustomerNameErrors"
                              required
                              @input="$v.selectedCustomerName.$touch()"
                              @blur="$v.selectedCustomerName.$touch()"
                              readonly
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="selectedCustomerEmail"
                              label="Customer email "
                              prepend-icon="mdi-account"
                              :error-messages="selectedCustomerEmailErrors"
                              required
                              @input="$v.selectedCustomerEmail.$touch()"
                              @blur="$v.selectedCustomerEmail.$touch()"
                              readonly
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="selectedCustomerContact"
                              label="Customer contact "
                              prepend-icon="mdi-account"
                              :error-messages="selectedCustomerContactErrors"
                              required
                              @input="$v.selectedCustomerContact.$touch()"
                              @blur="$v.selectedCustomerContact.$touch()"
                              readonly
                              disabled
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12"
                            ><v-menu
                              v-model="birthdayMenu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="birthdayEdit"
                                  label="Birthday"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  disabled
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="birthdayEdit"
                                no-title
                                @click="birthdayMenu2 = false"
                                dark
                                color="secondary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12">
                            <v-textarea
                              v-model="addressEdit"
                              clearable
                              prepend-icon="mdi-map-marker"
                              clear-icon="mdi-close"
                              label="Address"
                              readonly
                              disabled
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="
                            (dialog.value = false),
                              (selectedAppointment = ''),
                              (appointmentID = ''),
                              (selectedOpen = false),
                              (incompleteInfo = false),
                              $v.$reset()
                          "
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="updateAppointment()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-toolbar-title
                  v-html="selectedEvent.name"
                  class="white--text"
                  v-if="industry == 'F&B'"
                ></v-toolbar-title>
                <v-toolbar-title
                  v-html="selectedEvent.service_name"
                  class="white--text"
                  v-if="industry != 'F&B'"
                ></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="
                    (selectedBookingID = selectedEvent.bookingID),
                      deleteBooking()
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text style="background-color: white !important">
                <h3><b>Booking details</b></h3>
                <!-- <span v-html="selectedEvent.details"></span> -->
                <!-- <br /> -->
                <span v-html="'Remark: ' + selectedEvent.remark"></span>
                <br />
                <span v-html="'Customer: ' + selectedEvent.customerName"></span>
              </v-card-text>
              <!-- <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions> -->
              <!-- </v-card> -->
            </v-menu>
          </v-sheet>
        </v-card>
        <v-row class="my-12">
          <v-col cols="12">
            <v-card>
              <v-toolbar color="primary">
                <h2 class="text-left ml-4 white--text">
                  This's {{ type }} schedule
                </h2>
                <v-spacer></v-spacer>
                <v-btn text icon dark @click="generateReport()"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
              </v-toolbar>

              <v-list two-line flat>
                <p v-if="weekBooking == ''" class="text-h6 text-center pt-3">
                  No Appointment
                </p>

                <v-list-item-group color="primary" v-else>
                  <template v-for="(item, index) in weekBooking">
                    <v-list-item :key="item.bookingID">
                      <template>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            class="primary--text"
                            v-text="item.person + ' Person'"
                            v-if="industry == 'F&B'"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="primary--text"
                            v-text="item.service_title"
                            v-if="industry != 'F&B'"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle
                            v-text="item.service_description"
                            v-if="industry != 'F&B'"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="font-weight-black"
                            v-text="item.name"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="black--text"
                            v-text="item.remark"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle
                            class="font-weight-black"
                            v-text="item.contact"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle
                            v-text="
                              item.selected_date + ' ' + item.selected_time
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text
                            v-text="item.duration + ' min'"
                          ></v-list-item-action-text>

                          <v-btn
                            icon
                            :href="
                              'https://api.whatsapp.com/send?phone=+6' +
                              item.contact
                            "
                            target="_blank"
                            text
                            color="green darken-3"
                          >
                            <v-icon> mdi-whatsapp </v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < weekBooking.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="add" transition="dialog-top-transition" max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          medium
          right
          fixed
          bottom
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="indigo" dark>Add appointment</v-toolbar>

          <v-card-text>
            <v-alert
              class="mt-3"
              dense
              outlined
              type="error"
              v-if="incompleteMsg"
            >
              Please <strong>complete</strong> all the information needed
            </v-alert>
            <v-alert
              class="mt-3"
              dense
              outlined
              type="error"
              v-if="contactExist"
            >
              Contact number existed. Please use another number
            </v-alert>
            <v-row class="mt-2">
              <v-col cols="12" v-if="industry == 'F&B'">
                <v-select
                  :items="branchItem"
                  v-model="selectBranch"
                  item-text="name"
                  item-value="branch_id"
                  label="Branch"
                  prepend-icon="mdi-store"
                  return-object
                  @change="
                    getBranchWorkDayAndTimeForAdd(), findMaxPersonForAdd();
                    (selectPerson = ''), (selectTime = ''), (selectDate = {});
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" v-if="industry != 'F&B'">
                <v-select
                  :items="branchItem"
                  v-model="selectBranch"
                  item-text="name"
                  item-value="branch_id"
                  label="Branch"
                  prepend-icon="mdi-store"
                  return-object
                  @change="
                    getBranchWorkDayAndTimeForAdd(),
                      findAllServiceForAdd(),
                      (selectService = ''),
                      (selectProvider = ''),
                      (selectTime = ''),
                      (selectDate = {})
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" v-if="industry != 'F&B'">
                <v-select
                  :items="allService"
                  v-model="selectService"
                  item-text="title"
                  item-value="service_id"
                  label="Service"
                  prepend-icon="mdi-toolbox"
                  return-object
                  v-if="selectBranch"
                  @change="
                    (selectProvider = ''), (selectTime = ''), (selectDate = {})
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" v-if="industry != 'F&B'">
                <v-select
                  :items="allProvider"
                  v-model="selectProvider"
                  item-text="name"
                  item-value="provider_id"
                  label="Provider"
                  prepend-icon="mdi-account-group"
                  return-object
                  v-if="selectService"
                  @click="calendar = false"
                  @change="
                    (calendar = true), (selectTime = ''), (selectDate = {})
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" v-if="industry == 'F&B'">
                <v-text-field
                  v-model="selectPerson"
                  label="Total Person"
                  prepend-icon="mdi-account"
                  @click="
                    (calendar = false),
                      (selectDateToAdd = ''),
                      (selectDate = {})
                  "
                  v-if="selectBranch"
                  :error-messages="selectPersonErrors"
                  required
                  @input="(calendar = true), $v.selectPerson.$touch()"
                  @blur="$v.selectPerson.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <FunctionalCalendar
                  v-model="selectDate"
                  :limits="{ min: today, max: '01/01/3000' }"
                  :disabledDates="branchHolidays"
                  :disabled-day-names="weekdays"
                  :hidden-elements="['leftAndRightDays']"
                  :is-date-picker="true"
                  v-if="
                    (selectPerson && calendar && !$v.selectPerson.$invalid) ||
                    (selectService && selectProvider && calendar)
                  "
                  v-on:choseDay="
                    (selectDateToAdd = selectDate.selectedDate),
                      (selectTime = '');
                    getBookingForAdd();
                  "
                >
                </FunctionalCalendar>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="timesPlusDuration"
                  v-model="selectTime"
                  label="Pick your time"
                  prepend-icon="mdi-clock"
                  single-line
                  v-if="selectDateToAdd"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="existCustomer"
                  label="Add appointment to existing customer"
                  v-if="selectTime"
                ></v-checkbox>
              </v-col>

              <v-col cols="12" v-if="!existCustomer">
                <v-text-field
                  v-model="selectName"
                  label="Customer name "
                  prepend-icon="mdi-card-account-details-outline"
                  v-if="selectTime"
                  :error-messages="selectNameErrors"
                  required
                  @input="$v.selectName.$touch()"
                  @blur="$v.selectName.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!existCustomer">
                <v-text-field
                  v-model="selectEmail"
                  label="Customer email "
                  prepend-icon="mdi-email"
                  v-if="selectTime"
                  :error-messages="selectEmailErrors"
                  required
                  @input="$v.selectEmail.$touch()"
                  @blur="$v.selectEmail.$touch()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="!existCustomer">
                <v-text-field
                  v-model="selectContact"
                  label="Customer contact "
                  prepend-icon="mdi-phone"
                  v-if="selectTime"
                  :error-messages="selectContactErrors"
                  required
                  @input="$v.selectContact.$touch()"
                  @blur="$v.selectContact.$touch()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="!existCustomer">
                <v-menu
                  v-model="birthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-if="selectTime"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthday"
                      label="Birthday(Optional)"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="birthday"
                    no-title
                    @click="birthdayMenu = false"
                    dark
                    color="secondary"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" v-if="!existCustomer">
                <v-textarea
                  clearable
                  prepend-icon="mdi-map-marker"
                  clear-icon="mdi-close"
                  label="Address(Optional)"
                  v-model="address"
                  v-if="selectTime"
                ></v-textarea>
              </v-col>

              <v-col cols="12" v-if="!existCustomer">
                <v-textarea
                  v-model="selectRemark"
                  label="Apointment Remark(Optional)"
                  rows="5"
                  prepend-icon="mdi-comment"
                  v-if="selectTime"
                ></v-textarea>
              </v-col>
              <v-col cols="12" v-if="existCustomer">
                <v-autocomplete
                  :items="customer"
                  v-model="selectCustomer"
                  label="Choose customer"
                  prepend-icon="mdi-account-group"
                  single-line
                  item-text="contact"
                  item-value="customer_id"
                  return-object
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.name }} - {{ data.item.contact }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.name }} - {{ data.item.contact }}
                  </template>
                </v-autocomplete>

                
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="blue darken-1"
              text
              @click="
                (dialog.value = false),
                  (selectedOpen = false),
                  (incompleteMsg = false),
                  $v.$reset()
              "
            >
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="addAppointment()">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="primary" :timeout="timeout">
      {{ state }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- pdf generate -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      filename="Report"
      :paginate-elements-by-height="1100"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="false"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item" v-if="industry != 'F&B'">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Time</th>
                  <th class="text-left">Branch</th>
                  <th class="text-left">Service</th>
                  <th class="text-left">Provider</th>
                  <th class="text-left">Customer Name</th>
                  <th class="text-left">Customer Contact</th>
                  <th class="text-left">Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in bookingForPrint" :key="item.booking_id">
                  <td>{{ item.selected_date }}</td>
                  <td>{{ item.selected_time }}</td>
                  <td>{{ item.branch_id }}</td>
                  <td>{{ item.service_title }}</td>
                  <td>{{ item.provider_id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.contact }}</td>
                  <td>{{ item.remark }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </section>

        <section class="pdf-item" v-if="industry == 'F&B'">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Time</th>
                  <th class="text-left">Branch</th>
                  <th class="text-left">Person</th>
                  <th class="text-left">Customer Name</th>
                  <th class="text-left">Customer Contact</th>
                  <th class="text-left">Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in bookingForPrint" :key="item.booking_id">
                  <td>{{ item.selected_date }}</td>
                  <td>{{ item.selected_time }}</td>
                  <td>{{ item.branch_id }}</td>
                  <td>{{ item.person }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.contact }}</td>
                  <td>{{ item.remark }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </section>
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
// Components

import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { FunctionalCalendar } from "vue-functional-calendar";
import { validationMixin } from "vuelidate";
import VueHtml2pdf from "vue-html2pdf";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

export default {
  name: "Dashboard",

  mixins: [validationMixin],

  validations: {
    //edit appointment
    selectedPerson: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue(value) {
        return maxValue(this.maxPerson)(value);
      },
    },
    selectedCustomerName: {
      required,
    },
    selectedCustomerEmail: {
      required,
      email,
    },
    selectedCustomerContact: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },

    ///add appointment
    selectPerson: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue(value) {
        return maxValue(this.maxPerson)(value);
      },
    },
    selectName: {
      required,
    },
    selectEmail: {
      required,
      email,
    },
    selectContact: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    // address: { required },
    // birthday: {
    //   required,
    // },
    // addressEdit: { required },
    // birthdayEdit: {
    //   required,
    // },
  },

  components: {
    FunctionalCalendar,
    VueHtml2pdf,
  },

  data: () => ({
    domain: BASEURL,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    company_id: "",
    branchItem: [],
    selectedBranch: "0",
    specificBranchBooking: [],
    branchName: "all",
    menu: false,
    serviceID: "",
    date: "",
    branchHolidayWorkdayTime: [],
    selectedPerson: "",
    maxPerson: "",
    serviceDuration: "",
    slot: "",
    table: [],
    workingDays: [],
    branchHoliday: [],
    selectedDate: "",
    weekBooking: [],
    selectedCalendarDate: {},
    branchWorkingTime: [],
    gap: "",
    timesPlusDuration: [],
    booking: [],
    selectedTime: "",
    selectedCustomerName: "",
    selectedCustomerEmail: "",
    selectedCustomerContact: "",
    selectedCustomerRemark: "",
    selectedService: "",
    selectedProvider: "",
    selectPerson: "",
    selectBranch: "",
    selectDate: {},
    selectTime: "",
    selectName: "",
    selectEmail: "",
    selectContact: "",
    selectRemark: "",
    branchHolidays: "",
    existCustomer: false,
    selectDateToAdd: "",
    customer: [],
    selectCustomer: "",
    customerForAdd: "",
    state: "",
    snackbar: false,
    timeout: 2000,
    selectedBookingID: "",
    industry: "",
    allService: [],
    allProvider: [],
    selectService: "",
    selectProvider: "",
    providerWorkDay: [],
    providerWorkTime: [],
    providerBreakTime: [],
    providerID: "",
    serviceName: "",
    serviceDescription: "",
    calendar: "",
    selectedBranchID: "",
    calendarForEdit: true,
    selectedCustomerID: "",
    serviceItem: [],
    providerItem: [],
    incompleteInfo: false,
    incompleteMsg: false,
    companyProvider: "",
    add: "",
    birthdayMenu: false,
    birthdayMenu2: false,
    address: "",
    addressEdit: "",
    birthday: "",
    birthdayEdit: "",
    contactExist: false,
  }),

  computed: {
    //validation for edit appointment F&B
    selectedPersonErrors() {
      const errors = [];
      if (!this.$v.selectedPerson.$dirty) return errors;
      !this.$v.selectedPerson.numeric &&
        errors.push("Person must consist only number");
      !this.$v.selectedPerson.minValue && errors.push("At least 1 person");
      !this.$v.selectedPerson.maxValue &&
        errors.push("Maximum " + this.maxPerson + " Person ");
      !this.$v.selectedPerson.required && errors.push("Person is required");
      return errors;
    },
    //validation for add appointment F&B
    selectPersonErrors() {
      const errors = [];
      if (!this.$v.selectPerson.$dirty) return errors;
      !this.$v.selectPerson.numeric &&
        errors.push("Person must consist only number");
      !this.$v.selectPerson.minValue && errors.push("At least 1 person");
      !this.$v.selectPerson.maxValue &&
        errors.push("Maximum " + this.maxPerson + " Person ");
      !this.$v.selectPerson.required && errors.push("Person is required");
      return errors;
    },

    selectedCustomerNameErrors() {
      const errors = [];
      if (!this.$v.selectedCustomerName.$dirty) return errors;
      !this.$v.selectedCustomerName.required &&
        errors.push("Customer name is required");
      return errors;
    },
    selectedCustomerContactErrors() {
      const errors = [];
      if (!this.$v.selectedCustomerContact.$dirty) return errors;
      !this.$v.selectedCustomerContact.numeric &&
        errors.push("Phone must consist only number");
      !this.$v.selectedCustomerContact.minLength &&
        errors.push("Phone must at least 10 number");
      !this.$v.selectedCustomerContact.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.selectedCustomerContact.required &&
        errors.push("Phone is required");
      return errors;
    },
    selectedCustomerEmailErrors() {
      const errors = [];
      if (!this.$v.selectedCustomerEmail.$dirty) return errors;
      !this.$v.selectedCustomerEmail.email &&
        errors.push("Must be valid e-mail");
      !this.$v.selectedCustomerEmail.required &&
        errors.push("E-mail is required");
      return errors;
    },
    selectNameErrors() {
      const errors = [];
      if (!this.$v.selectName.$dirty) return errors;
      !this.$v.selectName.required && errors.push("Customer name is required");
      return errors;
    },
    selectContactErrors() {
      const errors = [];
      if (!this.$v.selectContact.$dirty) return errors;
      !this.$v.selectContact.numeric &&
        errors.push("Phone must consist only number");
      !this.$v.selectContact.minLength &&
        errors.push("Phone must at least 10 number");
      !this.$v.selectContact.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.selectContact.required && errors.push("Phone is required");
      return errors;
    },
    selectEmailErrors() {
      const errors = [];
      if (!this.$v.selectEmail.$dirty) return errors;
      !this.$v.selectEmail.email && errors.push("Must be valid e-mail");
      !this.$v.selectEmail.required && errors.push("E-mail is required");
      return errors;
    },

    // addressErrors() {
    //   const errors = [];
    //   if (!this.$v.address.$dirty) return errors;
    //   !this.$v.address.required && errors.push("Address is required");
    //   return errors;
    // },

    // birthdayErrors() {
    //   const errors = [];
    //   if (!this.$v.birthday.$dirty) return errors;
    //   !this.$v.birthday.required && errors.push("Birthday is required");
    //   return errors;
    // },

    // addressEditErrors() {
    //   const errors = [];
    //   if (!this.$v.addressEdit.$dirty) return errors;
    //   !this.$v.addressEdit.required && errors.push("Address is required");
    //   return errors;
    // },

    // birthdayEditErrors() {
    //   const errors = [];
    //   if (!this.$v.birthdayEdit.$dirty) return errors;
    //   !this.$v.birthdayEdit.required && errors.push("Birthday is required");
    //   return errors;
    // },

    /////push event booking to calendar
    getSpecificBranchBooking() {
      var booking = [];
      var period = "m";
      var moment = require("moment"); // require
      moment().format();
      for (let i = 0; i < this.specificBranchBooking.length; i++) {
        var startDate = moment(
          this.specificBranchBooking[i].selected_date,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        var startTime = moment(
          this.specificBranchBooking[i].selected_time,
          "hhmm"
        ).format("HH:mm");
        var start = startDate + " " + startTime;

        var endDate = startDate;
        var endTime = moment(startTime, "HH:mm")
          .add(this.specificBranchBooking[i].duration, period)
          .format("HH:mm");
        var end = endDate + " " + endTime;
        var changeDateFormat = moment(startDate).format("D/M/YYYY");

        var name = "";

        if (this.industry == "F&B") {
          name =
            JSON.stringify(this.specificBranchBooking[i].person) + " Person";
        } else {
          name = this.specificBranchBooking[i].service_title;
        }

        booking.push({
          name: name,
          start: start,
          end: end,
          color: this.specificBranchBooking[i].color,
          details: this.specificBranchBooking[i].service_description,
          person: this.specificBranchBooking[i].person,
          remark: this.specificBranchBooking[i].remark,
          email: this.specificBranchBooking[i].email,
          contact: this.specificBranchBooking[i].contact,
          customerID: this.specificBranchBooking[i].customer_id,
          customerName: this.specificBranchBooking[i].name,
          birthday: this.specificBranchBooking[i].birthday,
          address: this.specificBranchBooking[i].address,
          bookingID: this.specificBranchBooking[i].booking_id,
          date: startDate,
          time: startTime,
          serviceID: this.specificBranchBooking[i].service_id,
          branchID: this.specificBranchBooking[i].branch_id,
          service_name: this.specificBranchBooking[i].service_title,
          providerID: this.specificBranchBooking[i].provider_id,
          dateFormat: changeDateFormat,
        });
      }
      // this.getBookingToUpdate();
      return booking;
    },

    // find today date (use for calendar)
    today() {
      var d = new Date();
      var datestring =
        ("0" + d.getDate()).slice(-2) +
        "/" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        d.getFullYear();
      return datestring;
    },

    // show the work day of the branch or provider
    weekdays() {
      var value = [];
      if (this.workingDays[0] == 1 || this.providerWorkDay[0] == 1) {
        value.push("Su");
      }
      if (this.workingDays[1] == 1 || this.providerWorkDay[1] == 1) {
        value.push("Mo");
      }
      if (this.workingDays[2] == 1 || this.providerWorkDay[2] == 1) {
        value.push("Tu");
      }
      if (this.workingDays[3] == 1 || this.providerWorkDay[3] == 1) {
        value.push("We");
      }
      if (this.workingDays[4] == 1 || this.providerWorkDay[4] == 1) {
        value.push("Th");
      }
      if (this.workingDays[5] == 1 || this.providerWorkDay[5] == 1) {
        value.push("Fr");
      }
      if (this.workingDays[6] == 1 || this.providerWorkDay[6] == 1) {
        value.push("Sa");
      }

      return value;
    },

    // time session for F&B
    timeSession() {
      var moment = require("moment"); // require
      moment().format();
      var currentTime = moment(new Date(), "hmm").format("HH:mm");
      var currentDay = moment(new Date(), "ddmmyy").format("D/M/YYYY");
      var startTime = this.branchWorkingTime[0];
      var interval = this.gap;
      var times = [];
      var period = "m";
      var periodsInADay = moment.duration(1, "day").as(period);
      var startTimeMoment = moment(startTime, "HH:mm");
      var endTime = this.branchWorkingTime[1];
      var endTimeMoment = moment(endTime, "HH:mm");

      for (let i = 0; i <= periodsInADay; i++) {
        var time = startTimeMoment.add(i === 0 ? 0 : interval, period);
        if (this.selectDateToAdd == currentDay) {
          if (time.format("HH:mm") > currentTime && time <= endTimeMoment) {
            times.push(time.format("HH:mm"));
          }
        } else {
          if (time <= endTimeMoment) {
            times.push(time.format("HH:mm"));
          }
        }
      }

      return times;
    },

    //time session for service
    timeSessionForServiceType() {
      var moment = require("moment"); // require
      moment().format();
      var currentTime = moment(new Date(), "hmm").format("HH:mm");
      var currentDay = moment(new Date(), "ddmmyy").format("D/M/YYYY");
      var startTime = "";
      var endTime = "";
      var breakStartTime = this.providerBreakTime[0];
      var breakEndTime = this.providerBreakTime[1];
      if (this.selectedProvider != "" || this.selectProvider != "") {
        startTime = this.providerWorkTime[0];
        endTime = this.providerWorkTime[1];
      } else {
        startTime = this.branchWorkingTime[0];
        endTime = this.branchWorkingTime[1];
      }
      var breakStartTimeMoment = moment(breakStartTime, "HH:mm");
      var breakEndTimeMoment = moment(breakEndTime, "HH:mm");
      var interval = this.gap;
      var firstSession = [];
      var secondSession = [];
      var times = [];
      var period = "m";
      var periodsInADay = moment.duration(1, "day").as(period);
      var startTimeMoment = moment(startTime, "HH:mm");
      var endTimeMoment = moment(endTime, "HH:mm");

      for (let i = 0; i <= periodsInADay; i += interval) {
        var time = startTimeMoment.add(i === 0 ? 0 : interval, period);

        if (
          this.selectedCalendarDate.selectedDate == currentDay ||
          this.selectDate.selectedDate == currentDay
        ) {
          if (time.format("HH:mm") > currentTime && time <= endTimeMoment) {
            if (time <= breakStartTimeMoment) {
              firstSession.push(time.format("HH:mm"));
            }
            if (time >= breakEndTimeMoment) {
              secondSession.push(time.format("HH:mm"));
            }
            times = firstSession.concat(secondSession);
          }
        } else {
          if (time <= endTimeMoment) {
            if (time < breakStartTimeMoment) {
              firstSession.push(time.format("HH:mm"));
            }
            if (time >= breakEndTimeMoment) {
              secondSession.push(time.format("HH:mm"));
            }
            times = firstSession.concat(secondSession);
          }
        }
      }

      return times;
    },

    // the booking list for generate pdf
    bookingForPrint() {
      var booking = JSON.parse(JSON.stringify(this.weekBooking));
      var branch = this.branchItem;
      var provider = this.companyProvider;

      for (let i = 0; i < booking.length; i++) {
        for (let z = 0; z < branch.length; z++) {
          if (booking[i].branch_id == branch[z].branch_id) {
            booking[i].branch_id = branch[z].name;
          }
        }
      }

      if (this.industry != "F&B") {
        for (let i = 0; i < booking.length; i++) {
          for (let z = 0; z < provider.length; z++) {
            if (booking[i].provider_id == provider[z].provider_id) {
              booking[i].provider_id = provider[z].name;
            }
          }
        }
      }
      return booking;
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    var data = JSON.parse(this.getCookie("login"));
    this.company_id = data.company_id;
    this.industry = data.industry;
    this.getAllCustomer();
    this.getCompanyBranch();
    this.findAllBranchBooking();
    if (this.industry != "F&B") {
      this.getProviderByCompany();
    }
    this.setToday();
  },

  watch: {
    selectedBranch() {
      this.findSpecificBranchBooking();
    },

    // /////////edit appointment////////////

    selectedBranchID() {
      if (this.industry != "F&B") {
        this.getBranchWorkDayAndTimeForUpdate();
        this.getAllServiceForUpdate();
      } else {
        this.getBranchWorkDayAndTimeForUpdate();
        this.findMaxPersonForUpdate();
      }
    },

    selectedService() {
      if (this.industry != "F&B") {
        this.getAllProviderForUpdate();
      }
    },
    selectedPerson() {
      if (this.industry == "F&B") {
        this.getServiceToUpdate();
      }
    },

    ////////////add appointment//////////

    selectPerson() {
      if (this.industry == "F&B") {
        this.getServiceToAdd();
      }
    },
    selectService() {
      if (this.industry != "F&B") {
        this.getSelectedServiceForAdd();
      }
    },
    selectProvider() {
      if (this.industry != "F&B") {
        this.getSelectedProviderForAdd();
      }
    },
  },
  methods: {
    ///// For calendar
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
      this.getThisWeekBooking();
    },
    setToday() {
      var moment = require("moment"); // require
      var today = moment(new Date(), "yymmdd").format("YYYY-MM-DD");
      this.focus = today;
      this.getThisWeekBooking();
    },
    prev() {
      this.$refs.calendar.prev();
      this.getThisWeekBooking();
    },
    next() {
      this.$refs.calendar.next();
      this.getThisWeekBooking();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    getAllCustomer() {
      const params = new URLSearchParams();
      params.append("getAllCustomer", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.customer = response.data.customer;
          } else {
            console.log("no customer found on this company");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProviderByCompany() {
      const params = new URLSearchParams();
      params.append("getProviderByCompany", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/provider/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyProvider = response.data.provider;
          } else {
            console.log("no provider find");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("get", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchItem = response.data.branch;
          } else {
            console.log("no branch find");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findSpecificBranchBooking() {
      if (this.selectedBranch == 0) {
        this.findAllBranchBooking();
        this.getThisWeekBooking();
        this.branchName = "all";
        return;
      }

      const params = new URLSearchParams();
      params.append("findSpecificBranchBooking", "done");
      params.append("branch_id", this.selectedBranch);

      axios({
        method: "post",
        url: this.domain + "/booking/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.specificBranchBooking = response.data.booking;
            // this.getThisWeekBooking();
            this.getBranchName();
            this.getThisWeekBooking();
          } else {
            // this.findAllBranchBooking();
            // this.branchName = "all";
            this.specificBranchBooking = [];
            this.getThisWeekBooking();
            this.getBranchName();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBranchName() {
      for (var i = 0; i < this.branchItem.length; i++) {
        if (this.branchItem[i].branch_id == this.selectedBranch) {
          this.branchName = this.branchItem[i].name;
        }
      }
    },

    findAllBranchBooking() {
      const params = new URLSearchParams();
      params.append("findAllBranchBooking", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/booking/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.specificBranchBooking = response.data.booking;
            this.getThisWeekBooking();
          } else {
            console.log("bad");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBranchHolidayWorkdayTime() {
      const params = new URLSearchParams();
      params.append("getBranchHolidayWorkdayTime", "done");
      params.append("service_id", this.selectedEvent.serviceID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchHolidayWorkdayTime = response.data.branch[0];
            this.workingDays = JSON.parse(
              this.branchHolidayWorkdayTime.working_day
            );
            this.branchHoliday = JSON.parse(this.branchHolidayWorkdayTime.date);
            this.branchWorkingTime = JSON.parse(
              this.branchHolidayWorkdayTime.working_time
            );
            this.gap = this.branchHolidayWorkdayTime.gap;
          } else {
            console.log("no branch holiday, workingday and time found ");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getThisWeekBooking() {
      this.weekBooking = [];
      var moment = require("moment");
      var selectDay = this.focus;
      var weekBooking = [];
      var bookingDate = "";
      if (this.type == "month") {
        for (let i = 0; i < this.specificBranchBooking.length; i++) {
          bookingDate = moment(
            this.specificBranchBooking[i].selected_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          if (moment(bookingDate).isSame(selectDay, "month")) {
            weekBooking.push(this.specificBranchBooking[i]);
          }
        }
      } else if (this.type == "day") {
        for (let i = 0; i < this.specificBranchBooking.length; i++) {
          bookingDate = moment(
            this.specificBranchBooking[i].selected_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          if (moment(bookingDate).isSame(selectDay)) {
            weekBooking.push(this.specificBranchBooking[i]);
          }
        }
      } else if (this.type == "4day") {
        var add4days = moment(selectDay).add(3, "days").format("YYYY-MM-DD");
        for (let i = 0; i < this.specificBranchBooking.length; i++) {
          bookingDate = moment(
            this.specificBranchBooking[i].selected_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          if (
            moment(bookingDate).isBetween(selectDay, add4days, undefined, "[]")
          ) {
            weekBooking.push(this.specificBranchBooking[i]);
          }
        }
      } else if (this.type == "week") {
        var from_date = moment(selectDay)
          .clone()
          .startOf("week")
          .format("YYYY-MM-DD");
        var to_date = moment(selectDay)
          .clone()
          .endOf("week")
          .format("YYYY-MM-DD");
        for (let i = 0; i < this.specificBranchBooking.length; i++) {
          bookingDate = moment(
            this.specificBranchBooking[i].selected_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD");
          if (
            moment(bookingDate).isBetween(from_date, to_date, undefined, "[]")
          ) {
            weekBooking.push(this.specificBranchBooking[i]);
          }
        }
      }

      this.weekBooking = weekBooking;
    },

    //////////update booking///////////////

    getBranchWorkDayAndTimeForUpdate() {
      for (var i = 0; i < this.branchItem.length; i++) {
        if (this.branchItem[i].branch_id == this.selectedBranchID) {
          this.workingDays = JSON.parse(this.branchItem[i].working_day);
          this.branchWorkingTime = JSON.parse(this.branchItem[i].working_time);
          this.gap = JSON.parse(this.branchItem[i].gap);
        }
      }
      this.getBranchHolidayForUpdate();
    },
    getBranchHolidayForUpdate() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.selectedBranchID);
      axios({
        method: "post",
        url: this.domain + "/holiday/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchHolidays = JSON.parse(response.data.holiday[0].date);
          } else {
            console.log("no holiday");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    findMaxPersonForUpdate() {
      const params = new URLSearchParams();
      params.append("findMaxPerson", "done");
      params.append("branch_id", this.selectedBranchID);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.maxPerson = response.data.service[0].seat;
          } else {
            console.log("No max person found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBookingToUpdate() {
      if (this.industry == "F&B") {
        const params = new URLSearchParams();
        params.append("check", "done");
        params.append("selected_date", this.selectedDate);
        params.append("service_id", this.serviceID);
        params.append("booking_id", this.selectedBookingID);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.booking = response.data.booking;
              this.finalTime();
            } else {
              console.log("no booking");
              this.booking = [];
              this.finalTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("checkForService", "done");
        params.append("selected_date", this.selectedDate);
        params.append("provider_id", this.selectedProvider);
        params.append("booking_id", this.selectedBookingID);
        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.booking = response.data.booking;
              this.finalTime();
            } else {
              console.log("no booking");
              this.booking = [];
              this.finalTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getAllServiceForUpdate() {
      const params = new URLSearchParams();
      params.append("findAllService", "done");
      params.append("branch_id", this.selectedBranchID);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.serviceItem = response.data.service;
            this.getServiceInfo();
          } else {
            console.log("no service found");
            this.serviceItem = [];
            this.selectedService = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getServiceInfo() {
      for (var i = 0; i < this.serviceItem.length; i++) {
        if (this.serviceItem[i].service_id == this.selectedService) {
          this.serviceDuration = this.serviceItem[i].duration;
          this.serviceID = this.serviceItem[i].service_id;
          this.serviceName = this.serviceItem[i].title;
          this.serviceDescription = this.serviceItem[i].description;
          this.getBookingToUpdate();
        }
      }
    },

    getAllProviderForUpdate() {
      const params = new URLSearchParams();
      params.append("getAllProvider", "done");
      params.append("service_id", this.selectedService);

      axios({
        method: "post",
        url: this.domain + "/provider/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.providerItem = response.data.provider;
            this.getSelectedProviderForUpdate();
          } else {
            console.log("no provider found");
            this.providerItem = [];
            this.selectedProvider = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSelectedProviderForUpdate() {
      for (var i = 0; i < this.providerItem.length; i++) {
        if (this.providerItem[i].provider_id == this.selectedProvider) {
          this.providerWorkDay = JSON.parse(this.providerItem[i].work_day);
          this.providerWorkTime = JSON.parse(this.providerItem[i].work_time);
          this.providerBreakTime = JSON.parse(this.providerItem[i].break_time);
          this.slot = JSON.parse(this.providerItem[i].slot);
        }
      }
    },

    updateAppointment() {
      if (this.industry == "F&B") {
        this.$v.$touch();
        if (
          this.selectedBranchID == "" ||
          this.selectedPerson == "" ||
          this.selectedDate == "" ||
          this.selectedTime == "" ||
          this.selectedCustomerName == "" ||
          this.selectedCustomerEmail == "" ||
          this.selectedCustomerContact == ""
          // this.addressEdit == "" ||
          // this.birthdayEdit == ""
        ) {
          this.incompleteInfo = true;
          return;
        }
        if (this.selectedCustomerRemark == "") {
          this.selectedCustomerRemark = "none";
        }
        if (
          this.$v.selectedCustomerName.$invalid == false &&
          this.$v.selectedCustomerEmail.$invalid == false &&
          this.$v.selectedCustomerContact.$invalid == false
          // this.$v.addressEdit.$invalid == false &&
          // this.$v.birthdayEdit.$invalid == false
        ) {
          const params = new URLSearchParams();
          params.append("updateForFood", "done");
          params.append("booking_id", this.selectedBookingID);
          params.append("service_id", this.serviceID);
          params.append("person", this.selectedPerson);
          params.append("duration", this.serviceDuration);
          params.append("branch_id", this.selectedBranchID);
          params.append("selected_date", this.selectedDate);
          params.append("selected_time", this.selectedTime);
          params.append("service_title", this.table.title);
          params.append("service_description", this.table.description);
          params.append("remark", this.selectedCustomerRemark);
          params.append("customer_id", this.selectedCustomerID);
          params.append("name", this.selectedCustomerName);
          params.append("email", this.selectedCustomerEmail);
          params.append("contact", this.selectedCustomerContact);
          params.append("birthday", this.birthdayEdit);
          params.append("address", this.addressEdit);

          axios({
            method: "post",
            url: this.domain + "/booking/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.incompleteInfo = false;
                this.selectedOpen = false;
                this.snackbar = true;
                this.state = "your appointment is updated";
                setTimeout(function () {
                  location.reload();
                }, 2100);
              } else {
                console.log("Update Fail");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();
        if (
          this.selectedBranchID == "" ||
          this.selectedService == "" ||
          this.selectedProvider == "" ||
          this.selectedDate == "" ||
          this.selectedTime == "" ||
          this.selectedCustomerName == "" ||
          this.selectedCustomerEmail == "" ||
          this.selectedCustomerContact == ""
          // this.addressEdit == "" ||
          // this.birthdayEdit == ""
        ) {
          this.incompleteInfo = true;
          return;
        }
        if (this.selectedCustomerRemark == "") {
          this.selectedCustomerRemark = "none";
        }
        if (
          this.$v.selectedCustomerName.$invalid == false &&
          this.$v.selectedCustomerEmail.$invalid == false &&
          this.$v.selectedCustomerContact.$invalid == false
          // this.$v.addressEdit.$invalid == false &&
          // this.$v.birthdayEdit.$invalid == false
        ) {
          const params = new URLSearchParams();
          params.append("updateForService", "done");
          params.append("booking_id", this.selectedBookingID);
          params.append("service_id", this.serviceID);
          params.append("provider_id", this.selectedProvider);
          params.append("duration", this.serviceDuration);
          params.append("branch_id", this.selectedBranchID);
          params.append("selected_date", this.selectedDate);
          params.append("selected_time", this.selectedTime);
          params.append("service_title", this.serviceName);
          params.append("service_description", this.serviceDescription);
          params.append("remark", this.selectedCustomerRemark);
          params.append("customer_id", this.selectedCustomerID);
          params.append("name", this.selectedCustomerName);
          params.append("email", this.selectedCustomerEmail);
          params.append("contact", this.selectedCustomerContact);
          params.append("birthday", this.birthdayEdit);
          params.append("address", this.addressEdit);

          axios({
            method: "post",
            url: this.domain + "/booking/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.incompleteInfo = false;
                this.selectedOpen = false;
                this.snackbar = true;
                this.state = "your appointment is updated";
                setTimeout(function () {
                  location.reload();
                }, 2100);
              } else {
                console.log("Update Fail");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    getServiceToUpdate() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.selectedBranchID);
      params.append("seat", this.selectedPerson);
      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.table = response.data.service[0];
            this.serviceID = this.table.service_id;
            this.serviceDuration = this.table.duration;
            this.slot = this.table.slot;
            this.getBookingToUpdate();
          } else {
            console.log("cannot read service that you type");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //////////get the time ///////////
    finalTime() {
      var moment = require("moment"); // require
      moment().format();
      var duration = this.serviceDuration;
      this.timesPlusDuration = [];

      var first = [];

      if (this.industry == "F&B") {
        first = this.timeSession;
      } else {
        first = this.timeSessionForServiceType;
      }

      var period = "m";
      // var second = [];
      for (let i = 0; i < first.length; i++) {
        var start = moment(first[i], "HH:mm");
        var s = moment(first[i], "HH:mm");
        var end = s.add(duration, period);
        var slot = 0;
        for (let j = 0; j < this.booking.length; j++) {
          var bookingStart = moment(this.booking[j].selected_time, "HH:mm");
          var bookings = moment(this.booking[j].selected_time, "HH:mm");
          var bookingEnd = bookings.add(this.booking[j].duration, period);

          if (bookingStart >= start && bookingStart < end) {
            slot += 1;
            continue;
          }
          if (bookingEnd > start && bookingEnd <= end) {
            slot += 1;
            continue;
          }
          if (bookingStart < start && bookingEnd > end) {
            slot += 1;
            continue;
          }
        }

        if (slot < this.slot) {
          this.timesPlusDuration.push(start.format("HH:mm"));
        }
      }
    },

    ////////////////////////delete Booking/////////////////////////

    deleteBooking() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("booking_id", this.selectedBookingID);

      axios({
        method: "post",
        url: this.domain + "/booking/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.findAllBranchBooking();
            this.snackbar = true;
            this.state = "Booking is successfully deleted";
            console.log("delete success");
          } else {
            console.log("delete failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //////////////////////add Appointment Part////////////////////////

    addAppointment() {
      if (this.existCustomer) {
        if (this.industry == "F&B") {
          if (
            this.selectBranch == "" ||
            this.selectPerson == "" ||
            this.selectDateToAdd == "" ||
            this.selectTime == "" ||
            this.selectCustomer == ""
          ) {
            this.incompleteMsg = true;
            return;
          }

          if (this.selectRemark == "") {
            this.selectRemark = "none";
          }
          const params = new URLSearchParams();
          params.append("create", "done");
          params.append("service_id", this.serviceID);
          params.append("selected_time", this.selectTime);
          params.append("service_description", this.table.description);
          params.append("service_title", this.table.title);
          params.append("duration", this.serviceDuration);
          params.append("selected_date", this.selectDateToAdd);
          params.append("person", this.selectPerson);
          params.append("customer_id", this.selectCustomer.customer_id);
          params.append("branch_id", this.selectBranch.branch_id);
          params.append("remark", this.selectRemark);
          axios({
            method: "post",
            url: this.domain + "/booking/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                console.log("Booking successfully");
                this.findAllBranchBooking();
                this.add = false;
                this.selectedOpen = false;
                this.incompleteMsg = false;
                this.snackbar = true;
                this.state = "Appointment is added";

                this.selectBranch == "";
                this.selectPerson == "";
                this.selectDateToAdd == "";
                this.selectTime == "";
                this.selectCustomer == "";
              } else {
                console.log("Booking failed");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.industry != "F&B") {
          if (
            this.selectBranch == "" ||
            this.selectService == "" ||
            this.selectProvider == "" ||
            this.selectDateToAdd == "" ||
            this.selectTime == "" ||
            this.selectCustomer == ""
          ) {
            this.incompleteMsg = true;
            return;
          }
          if (this.selectRemark == "") {
            this.selectRemark = "none";
          }
          const params = new URLSearchParams();
          params.append("createBooking", "done");
          params.append("service_id", this.serviceID);
          params.append("selected_time", this.selectTime);
          params.append("duration", this.serviceDuration);
          params.append("service_title", this.serviceName);
          params.append("service_description", this.serviceDescription);
          params.append("selected_date", this.selectDateToAdd);
          params.append("person", 0);
          params.append("customer_id", this.selectCustomer.customer_id);
          params.append("provider_id", this.providerID);
          params.append("branch_id", this.selectBranch.branch_id);
          params.append("remark", this.selectRemark);

          axios({
            method: "post",
            url: this.domain + "/booking/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                console.log("Booking successfully");
                this.findAllBranchBooking();
                this.add = false;
                this.selectedOpen = false;
                this.incompleteMsg = false;
                this.snackbar = true;
                this.state = "Appointment is added";

                this.selectBranch == "";
                this.selectService == "";
                this.selectProvider == "";
                this.selectDateToAdd == "";
                this.selectTime == "";
                this.selectCustomer == "";
              } else {
                console.log("Booking failed");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.$v.$touch();

        if (this.industry == "F&B") {
          if (
            this.selectBranch == "" ||
            this.selectPerson == "" ||
            this.selectDateToAdd == "" ||
            this.selectTime == "" ||
            this.selectName == "" ||
            this.selectEmail == "" ||
            this.selectContact == ""
            // this.address == "" ||
            // this.birthday == ""
          ) {
            this.incompleteMsg = true;
            return;
          }
        } else if (this.industry != "F&B") {
          if (
            this.selectBranch == "" ||
            this.selectService == "" ||
            this.selectProvider == "" ||
            this.selectDateToAdd == "" ||
            this.selectTime == "" ||
            this.selectName == "" ||
            this.selectEmail == "" ||
            this.selectContact == ""
            // this.address == "" ||
            // this.birthday == ""
          ) {
            this.incompleteMsg = true;
            return;
          }
        }

        if (
          !this.$v.selectName.$invalid &&
          !this.$v.selectEmail.$invalid &&
          !this.$v.selectContact.$invalid
          // !this.$v.birthday.$$invalid &&
          // !this.$v.address.$$invalid
        ) {
          const params = new URLSearchParams();
          params.append("getCustomerInfo", "done");
          params.append("contact", this.selectContact);
          params.append("company_id", this.company_id);
          axios({
            method: "post",
            url: this.domain + "/customer/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.contactExist = true;
              } else {
                this.insertCustomer();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    insertCustomer() {
      const params = new URLSearchParams();
      params.append("create", "done");
      params.append("name", this.selectName);
      params.append("contact", this.selectContact);
      params.append("email", this.selectEmail);
      params.append("birthday", this.birthday);
      params.append("address", this.address);
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Add customer successfully");
            this.customerForAdd = response.data.customer;
            this.addAppointmentAndCustomer();
          } else {
            console.log("Please check again your info");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addAppointmentAndCustomer() {
      if (this.industry == "F&B") {
        if (this.selectRemark == "") {
          this.selectRemark = "none";
        }
        const params = new URLSearchParams();
        params.append("create", "done");
        params.append("service_id", this.serviceID);
        params.append("selected_time", this.selectTime);
        params.append("duration", this.serviceDuration);
        params.append("service_description", this.table.description);
        params.append("service_title", this.table.title);
        params.append("selected_date", this.selectDateToAdd);
        params.append("person", this.selectPerson);
        params.append("customer_id", this.customerForAdd);
        params.append("branch_id", this.selectBranch.branch_id);
        params.append("remark", this.selectRemark);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              console.log("Booking successfully");
              this.findAllBranchBooking();
              this.add = false;
              this.selectedOpen = false;
              this.incompleteMsg = false;
              this.snackbar = true;
              this.state = "Appointment is added";
              this.selectBranch == "";
              this.selectPerson == "";
              this.selectDateToAdd == "";
              this.selectTime == "";
              this.selectCustomer == "";
              this.selectName == "";
              this.selectEmail == "";
              this.selectContact == "";
              this.birthday == "";
              this.address == "";
            } else {
              console.log("Booking failed");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (this.selectRemark == "") {
          this.selectRemark = "none";
        }

        const params = new URLSearchParams();
        params.append("createBooking", "done");
        params.append("service_id", this.serviceID);
        params.append("selected_time", this.selectTime);
        params.append("duration", this.serviceDuration);
        params.append("service_title", this.serviceName);
        params.append("service_description", this.serviceDescription);
        params.append("selected_date", this.selectDateToAdd);
        params.append("person", 0);
        params.append("customer_id", this.customerForAdd);
        params.append("provider_id", this.providerID);
        params.append("branch_id", this.selectBranch.branch_id);
        params.append("remark", this.selectRemark);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              console.log("Booking successfully");
              this.findAllBranchBooking();
              this.add = false;
              this.selectedOpen = false;
              this.incompleteMsg = false;
              this.snackbar = true;
              this.state = "Appointment is added";

              this.selectBranch == "";
              this.selectService == "";
              this.selectProvider == "";
              this.selectDateToAdd == "";
              this.selectTime == "";
              this.selectCustomer == "";
              this.selectName == "";
              this.selectEmail == "";
              this.selectContact == "";
              this.address == "";
              this.birthday == "";
            } else {
              console.log("Booking failed");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    findMaxPersonForAdd() {
      const params = new URLSearchParams();
      params.append("findMaxPerson", "done");
      params.append("branch_id", this.selectBranch.branch_id);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.maxPerson = response.data.service[0].seat;
          } else {
            console.log("Booking failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getServiceToAdd() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.selectBranch.branch_id);
      params.append("seat", this.selectPerson);
      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log(response.data.service);
            this.table = response.data.service[0];
            this.serviceID = this.table.service_id;
            this.serviceDuration = this.table.duration;
            this.slot = this.table.slot;
          } else {
            console.log("cannot read service that you type");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBranchWorkDayAndTimeForAdd() {
      for (var i = 0; i < this.branchItem.length; i++) {
        if (this.branchItem[i].branch_id == this.selectBranch.branch_id) {
          this.workingDays = JSON.parse(this.branchItem[i].working_day);
          this.branchWorkingTime = JSON.parse(this.branchItem[i].working_time);
          this.gap = JSON.parse(this.branchItem[i].gap);
        }
      }
      this.getBranchHolidayForAdd();
    },
    getBranchHolidayForAdd() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("branch_id", this.selectBranch.branch_id);
      axios({
        method: "post",
        url: this.domain + "/holiday/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchHolidays = JSON.parse(response.data.holiday[0].date);
          } else {
            console.log("no holiday");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBookingForAdd() {
      if (this.industry == "F&B") {
        const params = new URLSearchParams();
        params.append("read", "done");
        params.append("selected_date", this.selectDateToAdd);
        params.append("service_id", this.serviceID);

        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.booking = response.data.booking;
              this.finalTime();
            } else {
              console.log("no booking");
              this.booking = [];
              this.finalTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("getBooking", "done");
        params.append("selected_date", this.selectDateToAdd);
        params.append("service_id", this.serviceID);
        params.append("provider_id", this.providerID);
        axios({
          method: "post",
          url: this.domain + "/booking/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.booking = response.data.booking;
              this.finalTime();
            } else {
              console.log("no booking");
              this.booking = [];
              this.finalTime();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    findAllServiceForAdd() {
      const params = new URLSearchParams();
      params.append("findAllService", "done");
      params.append("branch_id", this.selectBranch.branch_id);

      axios({
        method: "post",
        url: this.domain + "/service/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.allService = response.data.service;
          } else {
            console.log("no service found");
            this.allService = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSelectedServiceForAdd() {
      for (var i = 0; i < this.allService.length; i++) {
        if (this.allService[i].service_id == this.selectService.service_id) {
          this.serviceDuration = JSON.parse(this.allService[i].duration);
          this.serviceID = JSON.parse(this.allService[i].service_id);
          this.serviceName = this.allService[i].title;
          this.serviceDescription = this.allService[i].description;

          this.getAllProviderForAdd();
        }
      }
    },
    getAllProviderForAdd() {
      this.allProvider = [];
      const params = new URLSearchParams();
      params.append("getAllProvider", "done");
      params.append("service_id", this.selectService.service_id);

      axios({
        method: "post",
        url: this.domain + "/provider/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.allProvider = response.data.provider;
          } else {
            console.log("no provider found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSelectedProviderForAdd() {
      for (var i = 0; i < this.allProvider.length; i++) {
        if (
          this.allProvider[i].provider_id == this.selectProvider.provider_id
        ) {
          this.providerWorkDay = JSON.parse(this.allProvider[i].work_day);
          this.providerWorkTime = JSON.parse(this.allProvider[i].work_time);
          this.providerBreakTime = JSON.parse(this.allProvider[i].break_time);
          this.slot = JSON.parse(this.allProvider[i].slot);
          this.providerID = JSON.parse(this.allProvider[i].provider_id);
        }
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>


<style lang="sass">
#calendar
  .v-calendar-weekly__day:last-child,
  .v-calendar-weekly__head-weekday:last-child
    border-right: none

    .v-calendar-weekly__week:last-child .v-calendar-weekly__day
      border-bottom: none
</style>

<style>
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):not(.v-btn--hover) {
  color: black;
}
.v-text-field__details {
  margin-top: 2px;
}
</style>

